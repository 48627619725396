import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import WorkMultipleImage from "../components/molecules/WorkMultipleImage"
import ArrowText from "../components/atoms/ArrowText"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/www-foundation-004.png"
import GetInTouch from "../components/molecules/GetInTouch"

function WwwFoundation() {
  return (
    <>
      <Seo
        title="Helping end gender-based abuse online | Craig Walker x WWW Foundation"
        image={thumb}
        description="Craig Walker, working in partnership with Feminist Internet, was tasked with designing and faclitating Online Based Gender Violence (OBGV) workshops"
      />

      <Splash text={<>Helping end gender-based abuse online</>} work={true}>
        <p>
          <b>
            Globally, almost 40% of women report experiencing online violence,
            including threats and abusive language. This number is even higher
            among young women and girls.
          </b>
        </p>
        <p>
          The World Wide Web Foundation is a non-profit organisation that
          advocates for a free and open web for everyone, co-founded by
          Berners-Lee and Rosemary Leith. With the launch of its Tech Policy
          Design Lab, the Web Foundation set its first area of focus on tackling
          the violence and abuse that women disproportionately face online.
        </p>
        <p>
          Craig Walker, working in partnership with Feminist Internet, was
          tasked with designing and faclitating Online Based Gender Violence
          (OBGV) workshops and developing the outputs into recommendations.
          These formed the foundation of commitments aimed at addressing online
          gender-based abuse. Four of the world's biggest tech companies
          (Google, Facebook, Twitter and TikTok) agreed to these commitments at
          the 2021 2021 UN Generation Equality Forum.
        </p>
        <p>
          This work has been recognised with an award for Best Design Strategy
          at the 2021 Victorian Premiers Design Awards.
        </p>
        <br />
        <a
          href="https://premiersdesignawards.vic.gov.au/entries/2021/design-strategy/web-foundation-online-gender-based-violence"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            style={{ width: "200px", height: "auto" }}
            width="120"
            height="47"
            viewBox="0 0 120 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.5721 4.92228H70.5078C71.3146 4.92228 71.9383 5.11276 72.3791 5.49374C72.7974 5.84483 73.0065 6.29677 73.0065 6.84955C73.0065 7.26041 72.8908 7.6115 72.6592 7.90283C72.4351 8.19417 72.1176 8.39959 71.7068 8.51911C72.1176 8.60128 72.4575 8.78804 72.7264 9.07937C72.9953 9.36323 73.1298 9.72926 73.1298 10.1775C73.1298 11.0739 72.6853 11.6864 71.7964 12.0151C71.4602 12.1346 71.0344 12.1944 70.519 12.1944H67.5721V4.92228ZM70.4854 11.0403C70.9112 11.0403 71.2175 10.9544 71.4042 10.7825C71.5984 10.6107 71.6956 10.3829 71.6956 10.099C71.6956 9.81517 71.5947 9.58733 71.393 9.41552C71.1988 9.23624 70.8963 9.1466 70.4854 9.1466H68.8943V11.0403H70.4854ZM68.8943 7.99247H70.4518C70.8477 7.99247 71.1465 7.90657 71.3482 7.73476C71.5499 7.56295 71.6507 7.33137 71.6507 7.04004C71.6507 6.74124 71.5499 6.50593 71.3482 6.33412C71.154 6.15484 70.8589 6.0652 70.463 6.0652H68.8943V7.99247Z"
              fill="black"
            />
            <path
              d="M74.5465 4.92228H79.2303V6.0652H75.8687V7.98127H79.0174V9.12419H75.8687V11.0403H79.2303V12.1944H74.5465V4.92228Z"
              fill="black"
            />
            <path
              d="M84.664 6.92799C84.5445 6.29303 84.0963 5.97556 83.3194 5.97556C82.9385 5.97556 82.6397 6.05399 82.423 6.21086C82.2139 6.36027 82.1093 6.54328 82.1093 6.75991C82.1093 6.97655 82.1765 7.14836 82.311 7.27535C82.4529 7.39487 82.6695 7.50692 82.9609 7.6115L84.5408 8.22778C85.0786 8.43694 85.4932 8.70213 85.7846 9.02334C86.0759 9.33709 86.2216 9.733 86.2216 10.2111C86.2216 10.8684 85.975 11.3914 85.482 11.7798C84.989 12.1608 84.2868 12.3513 83.3755 12.3513C82.5687 12.3513 81.9151 12.1533 81.4146 11.7574C80.8543 11.3241 80.5592 10.7116 80.5294 9.91975H81.8404C81.8404 10.5174 82.1504 10.8946 82.7704 11.0515C82.9571 11.1038 83.2036 11.1299 83.5099 11.1299C83.8162 11.1299 84.1001 11.0552 84.3615 10.9058C84.623 10.7489 84.7537 10.5435 84.7537 10.2895C84.7537 10.0281 84.6827 9.83011 84.5408 9.69565C84.3989 9.55372 84.1673 9.42299 83.8461 9.30347L82.4006 8.73201C81.8404 8.51538 81.4108 8.2614 81.112 7.97006C80.8132 7.67873 80.6638 7.27535 80.6638 6.75991C80.6638 6.15484 80.8991 5.67675 81.3697 5.32566C81.8478 4.95963 82.494 4.77661 83.3082 4.77661C84.1299 4.77661 84.7612 4.9671 85.2019 5.34807C85.6501 5.72157 85.9078 6.24821 85.975 6.92799H84.664Z"
              fill="black"
            />
            <path
              d="M86.7336 4.92228H92.5602V6.0652H90.308V12.1944H88.9858V6.0652H86.7336V4.92228Z"
              fill="black"
            />
            <path
              d="M95.9459 4.92228H97.2681V12.1944H95.9459V4.92228Z"
              fill="black"
            />
            <path
              d="M100.492 4.92228L103.697 10.0766V4.92228H105.019V12.1944H103.674L100.369 6.86076V12.1944H99.0465V4.92228H100.492Z"
              fill="black"
            />
            <path
              d="M70.1829 14.711C70.6311 14.711 71.0195 14.7671 71.3482 14.8791C71.6769 14.9837 71.9607 15.1331 72.1998 15.3273C72.6704 15.7083 72.9767 16.2424 73.1186 16.9296L71.7068 17.1874C71.5872 16.8064 71.4005 16.5001 71.1465 16.2685C70.9 16.0295 70.59 15.91 70.2165 15.91C69.7085 15.91 69.2977 16.1229 68.9839 16.5487C68.6478 17.0118 68.4797 17.6542 68.4797 18.4759C68.4797 19.2902 68.6291 19.9289 68.9279 20.392C69.2267 20.8477 69.6338 21.0755 70.1492 21.0755C70.6647 21.0755 71.0531 20.9523 71.3146 20.7058C71.576 20.4518 71.7068 20.1268 71.7068 19.7309H73.1298C73.1298 20.5451 72.8684 21.1764 72.3454 21.6246C71.83 22.0653 71.1204 22.2857 70.2165 22.2857C69.2528 22.2857 68.4872 21.9495 67.9194 21.2772C67.3442 20.59 67.0566 19.6562 67.0566 18.4759C67.0566 17.2807 67.3517 16.347 67.9418 15.6747C68.5096 15.0322 69.2566 14.711 70.1829 14.711Z"
              fill="black"
            />
            <path
              d="M76.0744 14.8567H77.7327L80.1194 22.1288H78.7188L78.2146 20.5601H75.5141L74.9987 22.1288H73.5981L76.0744 14.8567ZM75.8503 19.5628H77.8896L76.8811 16.4366L75.8503 19.5628Z"
              fill="black"
            />
            <path
              d="M79.6494 14.8567H85.4761V15.9996H83.2238V22.1288H81.9016V15.9996H79.6494V14.8567Z"
              fill="black"
            />
            <path
              d="M86.2966 14.8567H90.9803V15.9996H87.6188V17.9157H90.7674V19.0586H87.6188V20.9747H90.9803V22.1288H86.2966V14.8567Z"
              fill="black"
            />
            <path
              d="M97.0528 20.9747C96.739 21.8487 96.1377 22.2857 95.2487 22.2857C94.3374 22.2857 93.5867 21.942 92.9965 21.2548C92.384 20.5451 92.0777 19.6375 92.0777 18.532C92.0777 17.3666 92.4064 16.4329 93.0638 15.7307C93.6987 15.0509 94.5167 14.711 95.5177 14.711C96.1974 14.711 96.7652 14.8604 97.2208 15.1592C97.684 15.4506 98.0463 15.9137 98.3077 16.5487L96.9519 16.8624C96.556 16.2274 96.0555 15.91 95.4504 15.91C94.8977 15.91 94.4382 16.1453 94.0722 16.6159C93.6912 17.094 93.5008 17.7327 93.5008 18.532C93.5008 19.2864 93.6726 19.899 94.0162 20.3696C94.3523 20.8327 94.8005 21.0643 95.3608 21.0643C95.8389 21.0643 96.2311 20.9112 96.5373 20.6049C96.8511 20.2912 97.0079 19.8616 97.0079 19.3163V19.2715L95.372 19.2379V18.3863H98.2741V22.1288H97.1984L97.0528 20.9747Z"
              fill="black"
            />
            <path
              d="M102.686 14.711C103.172 14.711 103.616 14.7932 104.02 14.9575C104.423 15.1144 104.77 15.3497 105.062 15.6635C105.674 16.3208 105.981 17.2621 105.981 18.4871C105.981 19.7197 105.674 20.6721 105.062 21.3444C104.479 21.9719 103.683 22.2857 102.675 22.2857C101.674 22.2857 100.882 21.9645 100.3 21.322C99.687 20.6572 99.3807 19.7122 99.3807 18.4871C99.3807 17.2471 99.687 16.2984 100.3 15.641C100.875 15.021 101.67 14.711 102.686 14.711ZM104.613 18.5096C104.613 17.6729 104.438 17.0305 104.087 16.5823C103.743 16.1266 103.276 15.8988 102.686 15.8988C102.096 15.8988 101.625 16.1266 101.274 16.5823C100.923 17.0305 100.748 17.6729 100.748 18.5096C100.748 19.3387 100.923 19.9774 101.274 20.4256C101.625 20.8664 102.096 21.0867 102.686 21.0867C103.276 21.0867 103.743 20.8664 104.087 20.4256C104.438 19.9774 104.613 19.3387 104.613 18.5096Z"
              fill="black"
            />
            <path
              d="M112.666 17.0865C112.666 17.6019 112.52 18.0427 112.229 18.4087C111.937 18.7747 111.519 19.0287 110.974 19.1707L112.923 22.1288H111.198L109.595 19.2939H108.587V22.1288H107.265V14.8567H109.954C110.813 14.8567 111.482 15.0621 111.96 15.473C112.43 15.8689 112.666 16.4067 112.666 17.0865ZM109.831 18.4087C110.316 18.4087 110.682 18.3004 110.929 18.0838C111.175 17.8597 111.299 17.5683 111.299 17.2098C111.299 16.8437 111.187 16.5524 110.962 16.3358C110.738 16.1117 110.402 15.9996 109.954 15.9996H108.587V18.4087H109.831Z"
              fill="black"
            />
            <path
              d="M114.902 14.8567L116.504 17.7588L118.185 14.8567H119.742L117.154 19.1258V22.1288H115.832V19.1258L113.333 14.8567H114.902Z"
              fill="black"
            />
            <path
              d="M67.7065 25.1441H69.6226C70.3173 25.1441 70.9168 25.2879 71.421 25.5755C71.929 25.8593 72.3212 26.2608 72.5976 26.78C72.874 27.2954 73.0121 27.9024 73.0121 28.6008C73.0121 29.2918 72.874 29.8969 72.5976 30.4161C72.3212 30.9352 71.929 31.3405 71.421 31.6318C70.9168 31.9194 70.3173 32.0632 69.6226 32.0632H67.7065V25.1441ZM68.3564 25.7043V31.5142H69.6226C70.1829 31.5142 70.6647 31.3872 71.0681 31.1332C71.4714 30.8792 71.7815 30.5337 71.9981 30.0967C72.2185 29.656 72.3286 29.1574 72.3286 28.6008C72.3286 28.0406 72.2185 27.5438 71.9981 27.1106C71.7815 26.6736 71.4714 26.3299 71.0681 26.0797C70.6647 25.8294 70.1829 25.7043 69.6226 25.7043H68.3564Z"
              fill="black"
            />
            <path
              d="M74.6701 32.0632V25.1441H78.8384V25.6987H75.32V28.2871H78.6367V28.8417H75.32V31.4974H78.8384V32.0632H74.6701Z"
              fill="black"
            />
            <path
              d="M82.6739 32.1865C82.222 32.1865 81.8074 32.1043 81.4302 31.94C81.0529 31.7719 80.7504 31.5291 80.5225 31.2116C80.2947 30.8904 80.1808 30.5001 80.1808 30.0407H80.8083C80.8083 30.3918 80.8942 30.6813 81.066 30.9091C81.2378 31.1369 81.4638 31.3069 81.7439 31.4189C82.0278 31.5272 82.334 31.5814 82.6627 31.5814C83.04 31.5814 83.363 31.5198 83.632 31.3965C83.9009 31.2695 84.1063 31.1052 84.2482 30.9035C84.3939 30.7018 84.4667 30.487 84.4667 30.2592C84.4667 29.9305 84.3659 29.6635 84.1642 29.458C83.9662 29.2489 83.6786 29.0864 83.3014 28.9706L82.1529 28.6008C81.559 28.3954 81.1127 28.1638 80.8139 27.9061C80.5151 27.6484 80.3657 27.2842 80.3657 26.8136C80.3657 26.2907 80.5636 25.8631 80.9595 25.5306C81.3555 25.1982 81.925 25.032 82.6683 25.032C83.3369 25.032 83.8617 25.1852 84.2426 25.4914C84.6273 25.7977 84.8496 26.1992 84.9093 26.696H84.265C84.2053 26.3524 84.0409 26.089 83.772 25.906C83.5068 25.7193 83.1389 25.6259 82.6683 25.6259C82.1604 25.6259 81.7663 25.7417 81.4862 25.9732C81.2098 26.2011 81.0716 26.4924 81.0716 26.8472C81.0716 27.1348 81.1594 27.3627 81.3349 27.5308C81.5105 27.6951 81.7868 27.8408 82.1641 27.9678L83.5535 28.416C84.0391 28.5654 84.4294 28.7876 84.7244 29.0827C85.0233 29.374 85.1727 29.7624 85.1727 30.248C85.1727 30.5879 85.0774 30.9054 84.8869 31.2004C84.6964 31.4955 84.4163 31.7345 84.0465 31.9175C83.6768 32.0968 83.2192 32.1865 82.6739 32.1865Z"
              fill="black"
            />
            <path
              d="M86.869 32.0632V25.1441H87.5189V32.0632H86.869Z"
              fill="black"
            />
            <path
              d="M91.9738 32.1865C91.4322 32.1865 90.9355 32.0464 90.4835 31.7663C90.0316 31.4862 89.6711 31.0865 89.4022 30.5673C89.137 30.0444 89.0044 29.4225 89.0044 28.7017C89.0044 28.0854 89.0922 27.5476 89.2678 27.0882C89.4433 26.6287 89.6824 26.2478 89.9849 25.9452C90.2912 25.639 90.6385 25.4111 91.027 25.2617C91.4154 25.1086 91.8207 25.032 92.2427 25.032C92.7283 25.032 93.1653 25.1291 93.5537 25.3233C93.9422 25.5138 94.2951 25.8164 94.6126 26.231L94.0467 26.5111C93.7629 26.2011 93.4697 25.977 93.1671 25.8388C92.8646 25.6969 92.5509 25.6259 92.2259 25.6259C91.774 25.6259 91.3538 25.7491 90.9653 25.9957C90.5806 26.2422 90.2706 26.5951 90.0353 27.0545C89.8037 27.5139 89.688 28.063 89.688 28.7017C89.688 29.2545 89.7795 29.7475 89.9625 30.1808C90.1492 30.614 90.4144 30.9558 90.758 31.206C91.1054 31.4563 91.52 31.5814 92.0018 31.5814C92.3566 31.5814 92.6741 31.4992 92.9542 31.3349C93.2381 31.1705 93.4603 30.9166 93.6209 30.5729C93.7853 30.2293 93.8675 29.7886 93.8675 29.2507H94.1252C94.1252 29.8446 94.0542 30.3619 93.9123 30.8026C93.7703 31.2434 93.5406 31.5851 93.2232 31.8279C92.9094 32.0669 92.493 32.1865 91.9738 32.1865ZM94.0075 32.0632L93.8675 30.3768V29.2395L92.0298 29.1723V28.7017H94.5118V32.0632H94.0075Z"
              fill="black"
            />
            <path
              d="M96.3374 25.1441H97.1778L100.887 31.2396H100.819V25.1441H101.469V32.0632H100.679L96.892 25.8388H96.9873V32.0632H96.3374V25.1441Z"
              fill="black"
            />
            <path
              d="M69.8187 42.1209C69.3668 42.1209 68.9522 42.0387 68.5749 41.8744C68.1977 41.7063 67.8952 41.4635 67.6673 41.146C67.4395 40.8248 67.3256 40.4345 67.3256 39.9751H67.953C67.953 40.3262 68.039 40.6157 68.2108 40.8435C68.3826 41.0713 68.6085 41.2413 68.8887 41.3533C69.1725 41.4617 69.4788 41.5158 69.8075 41.5158C70.1847 41.5158 70.5078 41.4542 70.7767 41.3309C71.0457 41.2039 71.2511 41.0396 71.393 40.8379C71.5387 40.6362 71.6115 40.4214 71.6115 40.1936C71.6115 39.8649 71.5107 39.5979 71.309 39.3924C71.111 39.1833 70.8234 39.0208 70.4462 38.905L69.2977 38.5353C68.7038 38.3298 68.2575 38.0983 67.9587 37.8405C67.6598 37.5828 67.5104 37.2187 67.5104 36.748C67.5104 36.2251 67.7084 35.7975 68.1043 35.4651C68.5002 35.1326 69.0698 34.9664 69.8131 34.9664C70.4817 34.9664 71.0064 35.1196 71.3874 35.4258C71.7721 35.7321 71.9944 36.1336 72.0541 36.6304H71.4098C71.3501 36.2868 71.1857 36.0234 70.9168 35.8404C70.6516 35.6537 70.2837 35.5603 69.8131 35.5603C69.3051 35.5603 68.9111 35.6761 68.631 35.9077C68.3546 36.1355 68.2164 36.4268 68.2164 36.7817C68.2164 37.0693 68.3041 37.2971 68.4797 37.4652C68.6552 37.6295 68.9316 37.7752 69.3089 37.9022L70.6983 38.3504C71.1839 38.4998 71.5742 38.722 71.8692 39.0171C72.168 39.3084 72.3174 39.6968 72.3174 40.1824C72.3174 40.5223 72.2222 40.8398 72.0317 41.1348C71.8412 41.4299 71.5611 41.6689 71.1913 41.852C70.8216 42.0312 70.364 42.1209 69.8187 42.1209Z"
              fill="black"
            />
            <path
              d="M72.9045 35.6331V35.0785H78.2101V35.6331H75.7282L75.8738 35.4819V41.9976H75.2239V35.4819L75.3696 35.6331H72.9045Z"
              fill="black"
            />
            <path
              d="M79.1721 41.9976V35.0785H81.5083C82.2777 35.0785 82.8548 35.2652 83.2395 35.6387C83.628 36.0085 83.8222 36.4922 83.8222 37.0898C83.8222 37.6837 83.628 38.1674 83.2395 38.5409C82.8548 38.9106 82.2777 39.0955 81.5083 39.0955H79.822V41.9976H79.1721ZM83.1219 41.9976L81.0601 38.9106H81.7829L83.9566 41.9976H83.1219ZM79.822 38.6137H81.4747C82.0238 38.6137 82.4402 38.4755 82.7241 38.1991C83.0117 37.919 83.1555 37.5567 83.1555 37.1122C83.1555 36.6565 83.0135 36.2961 82.7297 36.0309C82.4496 35.7657 82.0424 35.6331 81.5083 35.6331H79.822V38.6137Z"
              fill="black"
            />
            <path
              d="M84.715 41.9976L87.1073 35.0785H87.8748L90.2335 41.9976H89.55L88.9113 40.076H86.0596L85.3929 41.9976H84.715ZM86.2277 39.5493H88.732L87.4771 35.8852L86.2277 39.5493Z"
              fill="black"
            />
            <path
              d="M89.9003 35.6331V35.0785H95.2059V35.6331H92.724L92.8697 35.4819V41.9976H92.2198V35.4819L92.3654 35.6331H89.9003Z"
              fill="black"
            />
            <path
              d="M96.1567 41.9976V35.0785H100.325V35.6331H96.8066V38.2215H100.123V38.7762H96.8066V41.4318H100.325V41.9976H96.1567Z"
              fill="black"
            />
            <path
              d="M104.435 42.1209C103.894 42.1209 103.397 41.9808 102.945 41.7007C102.493 41.4206 102.132 41.0209 101.864 40.5017C101.598 39.9788 101.466 39.357 101.466 38.6361C101.466 38.0198 101.554 37.482 101.729 37.0226C101.905 36.5632 102.144 36.1822 102.446 35.8796C102.752 35.5734 103.1 35.3455 103.488 35.1961C103.877 35.043 104.282 34.9664 104.704 34.9664C105.19 34.9664 105.627 35.0635 106.015 35.2578C106.403 35.4482 106.756 35.7508 107.074 36.1654L106.508 36.4455C106.224 36.1355 105.931 35.9114 105.628 35.7732C105.326 35.6313 105.012 35.5603 104.687 35.5603C104.235 35.5603 103.815 35.6836 103.427 35.9301C103.042 36.1766 102.732 36.5295 102.497 36.9889C102.265 37.4484 102.149 37.9974 102.149 38.6361C102.149 39.1889 102.241 39.6819 102.424 40.1152C102.611 40.5484 102.876 40.8902 103.219 41.1404C103.567 41.3907 103.981 41.5158 104.463 41.5158C104.818 41.5158 105.135 41.4336 105.416 41.2693C105.699 41.105 105.922 40.851 106.082 40.5074C106.247 40.1637 106.329 39.723 106.329 39.1851H106.586C106.586 39.779 106.515 40.2963 106.374 40.7371C106.232 41.1778 106.002 41.5195 105.684 41.7623C105.371 42.0014 104.954 42.1209 104.435 42.1209ZM106.469 41.9976L106.329 40.3113V39.1739L104.491 39.1067V38.6361H106.973V41.9976H106.469Z"
              fill="black"
            />
            <path
              d="M107.74 35.0785H108.535L110.692 38.6417H110.485L112.659 35.0785H113.443L110.922 39.1627V41.9976H110.255V39.1627L107.74 35.0785Z"
              fill="black"
            />
            <path
              d="M23.1105 46.221C35.8741 46.221 46.221 35.8741 46.221 23.1105C46.221 10.3469 35.8741 0 23.1105 0C10.3469 0 0 10.3469 0 23.1105C0 35.8741 10.3469 46.221 23.1105 46.221Z"
              fill="black"
            />
            <path
              d="M11.4334 12.6622C11.5367 12.6976 11.6415 12.7297 11.743 12.7696C12.0641 12.896 12.2876 13.1247 12.442 13.4302C13.6445 15.8096 14.8486 18.1882 16.0523 20.5672C16.6804 21.8093 17.3096 23.0506 17.9314 24.2961C17.9691 24.371 17.9818 24.4938 17.9464 24.5646C17.2709 25.9145 16.5864 27.2599 15.9038 28.6057C15.8855 28.6419 15.862 28.6758 15.8259 28.7359C15.6002 28.2928 15.3872 27.8757 15.1761 27.4584C12.7236 22.6123 10.2715 17.7663 7.81828 12.9207C7.78583 12.8565 7.74293 12.7976 7.70489 12.736C7.68213 12.6211 7.76942 12.6629 7.81679 12.6629C8.39456 12.6611 8.97272 12.6618 9.55049 12.6618H11.4334V12.6622Z"
              fill="white"
            />
            <path
              d="M24.0395 12.6624C24.6579 13.0723 25.1757 13.5774 25.5532 14.2223C27.0568 16.7924 25.6244 20.1171 22.722 20.7781C21.1575 21.1343 19.8236 21.8778 18.7624 23.0905C18.7422 23.1136 18.7206 23.1352 18.6602 23.1994C18.6878 23.1151 18.6945 23.0793 18.7101 23.0479C20.4633 19.6012 22.2169 16.1549 23.9712 12.7086C23.9794 12.6922 23.9917 12.6776 24.0021 12.6624H24.0395Z"
              fill="white"
            />
            <path
              d="M29.8691 17.3694C32.5842 22.7335 35.2866 28.0723 37.9976 33.4285C37.9327 33.4326 37.8969 33.4367 37.8611 33.4367C36.7055 33.4367 35.5503 33.4461 34.3948 33.433C33.8625 33.427 33.4869 33.1424 33.2493 32.6736C32.7293 31.6475 32.2108 30.6202 31.6916 29.5937C30.388 27.0163 29.0851 24.4381 27.7774 21.8625C27.7061 21.7219 27.7031 21.6145 27.7762 21.472C28.4458 20.1676 29.1071 18.8591 29.7714 17.5518C29.7986 17.4984 29.8277 17.4458 29.8688 17.3694H29.8691Z"
              fill="white"
            />
            <path
              d="M21.7034 33.4562C21.1719 33.1432 20.7451 32.7456 20.3941 32.2644C18.5238 29.7003 19.8984 26.029 22.997 25.3259C24.5839 24.9659 25.9252 24.2038 26.9865 22.9643C27.0007 22.9475 27.0197 22.9348 27.0585 22.9341C25.2758 26.4367 23.4932 29.9397 21.7034 33.4562Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M56.0248 41.3188L56.0248 4.2019L56.375 4.2019L56.375 41.3188L56.0248 41.3188Z"
              fill="black"
            />
          </svg>
        </a>

        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage transparent>
          <Image imgName="www-foundation-5.png" />
        </WorkImage>

        <Numbers>
          <Number number={3} text="Global workshops" />
          <Number number={35} text="Countries represented" />
          <Number number="120+" text="Thought-leaders consulted" />
        </Numbers>

        <WorkTextBlock>
          <h2>Bringing together big tech</h2>
          <p>
            The three global workshops brought together 120+ thinkers, leaders
            and technologists from 35 countries to generate ideas and
            prototypes.
          </p>
          <p>
            Over the course of the workshops, groups developed solutions and
            prototypes for content curation and reporting flows for specific
            scenarios that focussed on the experiences and needs of highly
            visible women, such as politicians, journalists, activists and
            influences.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="www-foundation-6.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Prototyping to inform policy design</h2>
          <p>
            Participants generated innovative and illustrative design prototypes
            for making online spaces safer for women. They responded to a range
            of problems women reported facing online, from experiences with
            curating content (such as posts and comments) and reporting abuse.
            These prototypes informed recommendations presented at the 2021 UN
            Generation Equality Forum.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="www-foundation-1.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Creating a resource for product designers</h2>
          <p>
            A micro-site and report were created to share the recommendations
            and workshop approach in detail, with the goal of providing
            inspiration and tangible starting points for policy and product
            teams within tech companies. The complete prototypes and detailed
            personas are available for download from the{" "}
            <a
              style={{ borderBottom: "1px solid #000" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://techlab.webfoundation.org/ogbv/overview"
            >
              microsite
            </a>
            .
          </p>
          <p>
            The report outlined eight key recommendations. Each recommendation
            is supported by illustrative prototypes, design suggestions and
            considerations, and the acknowledgement of potential challenges.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="www-foundation-2.jpg" />
        </WorkImage>
        <WorkMultipleImage
          images={["www-foundation-3.jpg", "www-foundation-4.jpg"]}
        />
        <GetInTouch dataView="MakeReal" />
        <RelatedWork exclude="www-f" />
      </ContentHolder>
    </>
  )
}

export default WwwFoundation
